import { requestToken } from '@/utils/request.js'
import service from '@/utils/request'
import { getToken } from "@/utils/auth";
// 获取电站信息
export const getStationDetail = (data) => {
  return requestToken('/station/querySingle', data)
}

// 上传封面图
export const upLoadCover = (data) => {
  const dealData = new FormData()
  dealData.append("token", getToken());
  for (let key in data) {
      dealData.append(key, data[key])
  }
  return service({
      data: dealData,
      method: 'post',
      url: '/station/uploadFile',
      headers: {
          "Content-Type": "multipart/form-data",
      }
  })
}
