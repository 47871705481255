<template>
  <div class="flex-content">
    <div class="bgfff pd-20 header-title">电站信息</div>
    <div class="flex-main">
      <a-row :gutter="16">
        <a-col class="h100" :span="12">
          <div class="bgfff pd-20 mb-16">
            <h1 class="mb-16">电站封面</h1>
            <div>
              <div
                class="flex-center upload-img pointer"
                @click="openCover"
              >
                <img v-if="stationDetail.stationEntity.coverImg" :src="stationDetail.stationEntity.coverImg" alt="">
                <template v-else>
                  <a-icon style="font-size: 35px" type="instagram" />
                  <div>点击上传</div>
                </template>
              </div>
            </div>
          </div>

          <div class="bgfff pd-20 mb-16 msg-lsit">
            <h1 class="mb-16">系统信息</h1>
            

            <ul>
              <li class="flex-alignc-justifyb">
                <div>电站类型</div>
                <div>
                  <span v-if="stationDetail.stationEntity.stationType == 1">分布式户用</span>
                  <span v-else-if="stationDetail.stationEntity.stationType == 2">分布式商用</span>
                  <span v-else-if="stationDetail.stationEntity.stationType == 3">分布式工业</span>
                  <span v-else-if="stationDetail.stationEntity.stationType == 4">地面电站</span>
                </div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>系统类型</div>
                <div>
                  <span v-if="stationDetail.stationEntity.systemType == 1">光伏+电网</span>
                    <span v-else-if="stationDetail.stationEntity.systemType == 2">光伏+电网+用电</span>
                    <span v-else-if="stationDetail.stationEntity.systemType == 3">光伏+电网+用电+储能</span>
                </div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>装机容量</div>
                <div>{{stationDetail.stationEntity.capacity}}kWh</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>方位角</div>
                <div>{{stationDetail.stationEntity.azimuth}}°</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>倾角</div>
                <div>{{stationDetail.stationEntity.dipAngle}}°</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>并网日期</div>
                <div>{{stationDetail.stationEntity.mergeTime}}</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>并网状态</div>
                <div>
                  <span v-if="stationDetail.stationEntity.mergeState==1">已并网</span>
                  <span v-else>未并网</span>
                </div>
              </li>
              <!-- <li class="flex-alignc-justifyb">
                <div>累计运行天数 却字段</div>
                <div>累计运行天数</div>
              </li> -->
            </ul>
          </div>
          <div class="bgfff pd-20 msg-lsit">
            <h1 class="mb-16">业主信息</h1>
            <ul>
              <li class="flex-alignc-justifyb">
                <div>业主姓名</div>
                <div>{{stationDetail.stationEntity.merchantName}}</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>业主联系电话</div>
                <div>{{stationDetail.stationEntity.telPhone}}</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>业主工作单位</div>
                <div>{{stationDetail.stationEntity.company}}</div>
              </li>
            </ul>
          </div>
        </a-col>
        <a-col class="h100" :span="12">
          <div class="bgfff pd-20 mb-16 msg-lsit">
            <h1 class="mb-16">基础信息</h1>
            <ul>
              <li class="flex-alignc-justifyb">
                <div>电站名称</div>
                <div>{{stationDetail.stationEntity.stationName}}</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>地址</div>
                <div>{{stationDetail.stationEntity.address}}</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>电站ID</div>
                <div>{{stationDetail.stationEntity.id}}</div>
              </li>

              <li class="flex-alignc-justifyb">
                <div>建站日期</div>
                <div>{{stationDetail.stationEntity.createTime}}</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>位置</div>
                <div>经度:{{stationDetail.stationEntity.longitude}} 纬度:{{stationDetail.stationEntity.latitude}}</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>电站时区</div>
                <div>--</div>
              </li>
            </ul>
          </div>

          <div class="bgfff pd-20 mb-16 msg-lsit">
            <h1 class="mb-16">收益信息</h1>
            <ul>
              <li class="flex-alignc-justifyb">
                <div>货币单位</div>
                <div>{{stationDetail.stationEntity.currencyUnit}}</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>度电收益</div>
                <div>{{stationDetail.stationEntity.unitPrice}}</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>补贴收益</div>
                <div>{{stationDetail.stationEntity.subsidy}}</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>总成本</div>
                <div>{{stationDetail.stationEntity.cost}}</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>日还款</div>
                <div>{{stationDetail.stationEntity.dayRepayment}}</div>
              </li>
            </ul>
          </div>

          <div class="bgfff pd-20 msg-lsit">
            <h1 class="mb-16">电站管理员</h1>
            <ul>
              <li class="flex-alignc-justifyb">
                <div>管理者</div>
                <div>{{stationDetail.userEntity.name}}</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>联系人姓名</div>
                <div>{{stationDetail.userEntity.name}}</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>手机号</div>
                <div>{{stationDetail.userEntity.telPhone}}</div>
              </li>
              <li class="flex-alignc-justifyb">
                <div>邮箱</div>
                <div>{{stationDetail.userEntity.email}}</div>
              </li>
            </ul>
          </div>
        </a-col>
      </a-row>
    </div>
    <!-- 上传图片 -->
    <a-modal v-model="visible" title="编辑电站封面" @ok="handleOk">
      <a-upload
        v-if="!option.img"
        name="avatar"
        list-type="picture-card"
        class="avatar-uploader"
        :show-upload-list="false"
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        :before-upload="beforeUpload"
        @change="handleChange"
      >
        <div class="ant-upload-text">点击这里添加图片</div>
      </a-upload>
      <div v-else class="cropper">
        <a-icon class="close-icon" type="close-circle" @click="option.img=''" />
        <VueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
 import { getStationDetail,upLoadCover } from '@/api/station/basic'
import { VueCropper } from "vue-cropper";
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      id:'',
      stationDetail:{
        stationEntity:{}, // 电站信息
        userEntity:{}, // 电站管理员
      },

      visible: false, // 上传图片弹窗
      loading: false,
      imageUrl: "",
      option: {
        img: "", // 裁剪图片的地址
        info: false, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输
      }
    };
  },
  created() {
    this.id = this.$route.query.id
    this.getStationDetail()
  },
  methods: {
    // 获取电站信息
    async getStationDetail() {
      try {
        let res = await getStationDetail({
          id:this.id
        })
        this.stationDetail = res.data
      }catch(err) {

      }
    },

    // 打开上传弹窗
    openCover() {
      this.option.img = this.stationDetail.stationEntity.coverImg
      this.visible = true
    },
    // 上传封面图确认
    handleOk(e) {
      this.$refs.cropper.getCropBlob((data) => {
        upLoadCover({
          multipartFile:data,
          id:this.id
        }).then((res) => {
          this.getStationDetail()
          this.visible = false;
        });
      });
    },
    // 监听上传
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "removed") {
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
      }
    },
    // 上传之前
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      // ↓ 获取所需要上传图片的 Base64 编码
      getBase64(file, (cb_img) => {
        // 将获取到的所要上传图片的 Base64 编码渲染到图片上
        this.option.img = cb_img
      })
      return false
      // return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
.header-title {
  font-weight: bold;
  font-size: 28px;
}

.msg-lsit {
  h1 {
    font-size: 24px;
  }
  ul {
    line-height: 60px;
  }
}
.upload-img {
  width: 50%;
  height: 250px;
  background-color: hsla(0, 0%, 50.2%, 0.06);
  img {
    width: 100%;
    height: 100%;
  }
}
.cropper {
  position: relative;
  margin: 0 auto;
  width: 500px;
  height: 300px;
  .close-icon {
    position: absolute;
    top: -15px;
    right: -15px;
    font-size: 30px;
    z-index: 10;
  }
}
</style>